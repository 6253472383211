.unStyledLink {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.linkedElement:hover {
  color: #f999e6;
  text-decoration: none;
}
