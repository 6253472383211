.menuItem > .linkedElement:hover {
  color: inherit;
}

.topnav {
  color: white;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: 1px solid white;
}

.menuItem {
  display: flex;
  align-items: flex-start;
}

.topnav.open > .menuItem {
  display: flex;
}

.menuItem {
  padding: 0.5em 1em;
  text-decoration: none;
  font-size: 1.1em;
}

.menuExpander {
  padding: 0.8em 0.5em;
}

.main {
  display: flex;
  align-items: stretch;
}

.topnav .menuItem:hover {
  background-color: #555;
  color: #f999e6;
}

.headerLogo {
  height: 1.5rem;
  margin: 1rem;
}

.menuExpanderContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menuExpander > * {
  border: 1px solid white;
  padding: 0.5em;
  border-radius: 0.3em;
}

.spacer {
  flex: 1;
}

@media (max-width: 57em) {
  .menuItem:not(:first-child) {
    display: none;
    padding: 0.8em 1em;
  }

  .menuItem > .loggedInInfo {
    align-items: flex-start;
  }
}

@media (min-width: 57em) {
  .topnav {
    flex-direction: row;
    align-items: stretch;
  }

  .menuItem {
    display: flex;
    align-items: center;
  }

  .menuExpander {
    display: none;
  }

  .mobileOnly {
    display: none;
  }
}
