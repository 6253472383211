.dbottomnav {
  color: white;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid white;
}

.dmain {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.menuExpander {
  padding: 0.8em 0.5em;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.menuItems {
  display: none;
  flex-direction: column;
  align-items: center;
}

.menuItems.open {
  display: flex;
}

.dmenuItem {
  padding: 0.5em 1em;
  text-decoration: none;
  font-size: 1.1em;
  display: flex;
  align-items: center;
}

.dbottomnav .dmenuItem:hover {
  background-color: #555;
  color: #f999e6;
}

@media (min-width: 57em) {
  .menuItems {
    display: flex;
    flex-direction: row;
  }

  .menuExpander {
    display: none;
  }
}
