.doNotConsentButton {
  text-decoration: none;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1em;
  /*  font-size: 0.7em; */
  font-weight: 500;
}

.consentButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.consentButtons > * {
  margin: 0.2em;
}

.contentWarning {
  margin: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
