.button {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1em;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.button:disabled {
  cursor: default;
  color: gray;
}

.buttonText {
  vertical-align: middle;
}

.active {
  border: 1px solid rgba(250, 0, 194, 0.2);
  background-color: rgba(250, 0, 194, 0.7);
}
