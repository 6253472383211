.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.registerBox {
  margin-top: 5em;
}

.loginBox {
  display: flex;
  flex-direction: column;
  min-width: 50%;
}

.loginError {
  color: darkred;
  align-self: center;
  margin: 0.5em;
}

.input {
  display: flex;
  flex-direction: column;
  margin: 0.5em;
}

.input > label {
  font-size: 0.9em;
}
