body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    background-color: black;
    color: white;
    width: 100vw;
    max-width:100%;
}

textarea {
    font-family: 'Roboto', sans-serif;
}

strong {
    font-weight: 900;
}

#root {
    display: flex;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    max-width: 100%;
}

.pink {
    color: #f000c2;
}

.gold {
    color: #FFD700;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}


a {
    outline: none;
}